import React, { useState } from "react";
import "./styles/Contact.css";
import { MdOutlineMailOutline, MdOutlinePrivacyTip } from "react-icons/md";
import { IoMdCall } from "react-icons/io";
import { AnimatePresence, motion } from "framer-motion";
import emailjs from "@emailjs/browser";
import { CiUser, CiMail } from "react-icons/ci";
import { MdOutlineLocalPhone } from "react-icons/md";
import { MdOutlineMessage } from "react-icons/md";
import { IoMdClose } from "react-icons/io";

const Contact = ({ textEnter, textLeave }) => {
  const [formData, setFormData] = useState({
    from_name: "",
    from_email: "",
    from_phone: "",
    message: "",
  });

  const [privacyPopup, setPrivacyPopup] = useState(false);

  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    // Clear the red border when the user starts typing
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate the form
    const validationErrors = {};
    Object.keys(formData).forEach((key) => {
      if (key !== "from_phone" && !formData[key]) {
        validationErrors[key] = "This field is required";
      }
    });
    setErrors(validationErrors);

    // If there are validation errors, don't submit the form
    if (Object.keys(validationErrors).length > 0) {
      return;
    }

    // Proceed with sending the email
    const templateParams = { ...formData };

    // Add your emailjs service ID and template ID
    const serviceId = "service_iounkjv";
    const templateId = "template_f5h9xxi";
    const userId = "DXImp6D1uUTedrn-Z";

    emailjs.send(serviceId, templateId, templateParams, userId).then(
      (response) => {
        console.log("Email sent successfully:", response);
        // Clear the form after successful submission
        setFormData({
          from_name: "",
          from_email: "",
          from_phone: "",
          message: "",
        });
      },
      (error) => {
        console.error("Error sending email:", error);
      }
    );
  };

  const [toogleFormPopup, setToogleFormPopup] = useState(false);

  const handleToggleFormPopup = () => {
    setToogleFormPopup(!toogleFormPopup);
    document.body.style.overflow = !toogleFormPopup ? "hidden" : "scroll";
  };

  const variants = {
    open: { opacity: 1 },
    close: { opacity: 0 },
  };

  const handlePrivacyPopup = () => {
    setPrivacyPopup(!privacyPopup);
    document.body.style.overflow = !privacyPopup ? "hidden" : "scroll";
  };
  return (
    <>
      <div className="contact comman-section" id="contact">
        <motion.div
          initial="hidden"
          whileInView="visible"
          transition={{ duration: 1, delay: 0.5 }}
          variants={{
            visible: { opacity: 1, y: 0, visibility: "visible" },
            hidden: { opacity: 0, y: 100, visibility: "hidden" },
          }}
          viewport={{ once: true }}
        >
          <div
            className="sm-heading"
            onMouseEnter={textEnter}
            onMouseLeave={textLeave}
          >
            Contact
            <span></span>
          </div>
          <div
            className="git-container"
            onMouseEnter={textEnter}
            onMouseLeave={textLeave}
          >
            <h2>Get In Touch</h2>
            <div className="contact-btn">
              {/* <a href="tel:+919284113175" className="a-btn a-contact-btn">
                Call <IoMdCall />
              </a> */}
              <button
                className="a-btn a-contact-btn"
                onClick={handleToggleFormPopup}
              >
                Message
              </button>
              <a
                href="mailto:russeldsouza456@gmail.com"
                className="a-btn a-contact-btn"
              >
                Email
              </a>
            </div>
          </div>
        </motion.div>
      </div>
      {/* form below */}
      <AnimatePresence>
        {toogleFormPopup && (
          <motion.div
            variants={variants}
            animate="open"
            exit="close"
            initial="close"
            className="form-container"
          >
            <form
              onSubmit={handleSubmit}
              className="contactForm"
              id="contactForm"
            >
              <div className="form-in-container">
                <IoMdClose
                  className="close-btn"
                  onClick={handleToggleFormPopup}
                />
                <div className="dv-form-input">
                  <CiUser />
                  <input
                    className={`input-form ${
                      errors.from_name && "validate-input"
                    }`}
                    type="text"
                    name="from_name"
                    id="from_name"
                    placeholder="Name *"
                    value={formData.from_name}
                    onChange={handleChange}
                    required
                  />
                  {/* {errors.from_name && (
                      <p className="error-message">{errors.from_name}</p>
                    )} */}
                </div>
                <div className="dv-form-input">
                  <CiMail />
                  <input
                    className={`input-form ${
                      errors.from_email && "validate-input"
                    }`}
                    type="email"
                    name="from_email"
                    id="from_email"
                    placeholder="Email *"
                    required
                    value={formData.from_email}
                    onChange={handleChange}
                  />
                  {/* {errors.from_email && (
                      <p className="error-message">{errors.from_email}</p>
                    )} */}
                </div>
                <div className="dv-form-input">
                  <MdOutlineLocalPhone />
                  <input
                    className={`input-form ${
                      errors.from_phone && "validate-input"
                    }`}
                    type="text"
                    name="from_phone"
                    id="from_phone"
                    placeholder="Mobile Number"
                    value={formData.from_phone}
                    onChange={handleChange}
                  />
                  {/* {errors.from_phone && (
                      <p className="error-message">{errors.from_phone}</p>
                    )} */}
                </div>
                <div className="dv-form-input">
                  <MdOutlineMessage />
                  <textarea
                    className={`input-form ${
                      errors.message && "validate-input"
                    }`}
                    type="text"
                    name="message"
                    id="message"
                    placeholder="Enter message *"
                    value={formData.message}
                    onChange={handleChange}
                    required
                    cols="50"
                    rows="10"
                  />
                  {/* {errors.message && (
                      <p className="error-message">{errors.message}</p>
                    )} */}
                </div>
              </div>
              <div className="submit-btn-container">
                <input type="submit" value="Submit" style={{}} />
              </div>
            </form>
            <div className="close-bg" onClick={handleToggleFormPopup}></div>
          </motion.div>
        )}
      </AnimatePresence>

      <div
        className="privacy-icon-content"
        onClick={handlePrivacyPopup}
        onMouseEnter={textEnter}
        onMouseLeave={textLeave}
      >
        <MdOutlinePrivacyTip />
      </div>

      <AnimatePresence>
        {privacyPopup && (
          <motion.div
            className="privacy-popup-container"
            variants={variants}
            animate="open"
            exit="close"
            initial="close"
          >
            <div className="outer-close" onClick={handlePrivacyPopup}></div>
            <div className="pop-inner">
              <div className="header">
                <h3>Privacy policy</h3>
                <div
                  className="close-btn"
                  onClick={handlePrivacyPopup}
                  onMouseEnter={textEnter}
                  onMouseLeave={textLeave}
                >
                  <IoMdClose />
                </div>
              </div>
              <div className="content">
                <p>
                  This website collects minimal visitor data, such as IP
                  addresses, for the purpose of understanding website traffic
                  and improving performance. Your data is not shared or sold to
                  any third parties. For more information, please contact me at{" "}
                  <a
                    href="mailto:russeldsouza456@gmail.com"
                    target="_blank"
                    rel="noreferrer"
                    onMouseEnter={textEnter}
                    onMouseLeave={textLeave}
                  >
                    russeldsouza456@gmail.com
                  </a>
                  .
                </p>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default Contact;
