import { motion } from "framer-motion";
import React from "react";
import "./styles/Experience.scss";

const ExperienceV1 = ({ textEnter, textLeave }) => {
  const experienceData = [
    {
      company: "Futuready Media",
      position: "Web Developer - Full time",
      date: "Dec 2022 - Present",
      description: [
        "As a dedicated Web Developer, I thrive on creating exceptional web experiences using cutting-edge technologies such as HTML, CSS, and JavaScript. My passion for design and development drives me to craft responsive and user-friendly websites that leave a lasting impact on visitors. With a strong foundation in web technologies, I take pride in my ability to transform creative concepts into functional and visually appealing interfaces. Leveraging my expertise in HTML, CSS, and JavaScript, I ensure seamless navigation and optimal performance across various devices. As a detail-oriented professional, I am committed to delivering pixel-perfect designs and optimizing website performance for enhanced user engagement. My goal is to stay updated with the latest industry trends and best practices, allowing me to continuously elevate the quality of my work and provide innovative solutions to clients and end-users alike.",
      ],
    },
    {
      company: "Vistaar Digital Communications Pvt. Ltd.",
      position: "Associate Software Engineer - Internship",
      date: "Feb 2022 - May 2022",
      description: [
        "I worked as an Associate Software Engineer, developing websites using HTML, CSS, and Javascript ",
      ],
    },
  ];

  return (
    <motion.div
      className="experience--parent comman-section"
      id="experience"
      viewport={{ once: true }}
      initial="hidden"
      whileInView="visible"
      transition={{ duration: 1, delay: 1 }}
      variants={{
        visible: { opacity: 1, y: 0, visibility: "visible" },
        hidden: { opacity: 0, y: 50, visibility: "hidden" },
      }}
    >
      <div className="exp__container par__container">
        <div
          className="sm-heading"
          onMouseEnter={textEnter}
          onMouseLeave={textLeave}
        >
          <h2>Experience</h2>
          <span></span>
        </div>

        {experienceData.map((experience, index) => (
          <div
            key={index}
            className="exp__content"
            onMouseEnter={textEnter}
            onMouseLeave={textLeave}
          >
            <div className="exp__head__text">
              <h3>{experience.company}</h3>
            </div>
            <div className="exp__text">
              <h4>{experience.position}</h4>
              <p>{experience.date}</p>
              {experience.description.map((paragraph, idx) => (
                <p key={idx}>{paragraph}</p>
              ))}
            </div>
          </div>
        ))}
      </div>
    </motion.div>
  );
};

export default ExperienceV1;
