import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { firestore } from "./Components/firebase.config";
import { serverTimestamp } from "firebase/firestore";

import "./App.css";
import Nav from "./Components/Nav";
import Home from "./Components/Home";
import Footer from "./Components/Footer";

function App() {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [cursorVariant, setCursorVariant] = useState("default");

  useEffect(() => {
    const logVisit = async () => {
      try {
        const ipResponse = await fetch("https://api.ipify.org?format=json");
        const ipData = await ipResponse.json();
        const visitorIP = ipData.ip;

        const ipInfoResponse = await fetch(
          `https://ipinfo.io/${visitorIP}?token=${process.env.REACT_APP_IPINFO_TOKEN}`
        );
        const visitorData = await ipInfoResponse.json();

        const visitDetails = {
          ip: visitorData.ip,
          city: visitorData.city || "Unknown",
          region: visitorData.region || "Unknown",
          country: visitorData.country || "Unknown",
          location: visitorData.loc || "Unknown",
          org: visitorData.org || "Unknown",
          timestamp: serverTimestamp(),
        };

        console.log(visitorData);

        await firestore.collection("visitors").add(visitDetails);
        console.log("Visitor data logged:", visitDetails);
      } catch (error) {
        console.error("Error logging visit:", error);
      }
    };

    logVisit();
  }, []);

  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const cursorVariants = {
    default: {
      x: mousePosition.x - 15,
      y: mousePosition.y - 15,
    },
    text: {
      height: 60,
      width: 60,
      x: mousePosition.x - 30,
      y: mousePosition.y - 30,
      mixBlendMode: "difference",
    },
  };

  const textEnter = () => setCursorVariant("text");
  const textLeave = () => setCursorVariant("default");

  return (
    <>
      <motion.div
        className="cursor"
        variants={cursorVariants}
        animate={cursorVariant}
      />
      <div className="App">
        <Nav textEnter={textEnter} textLeave={textLeave} />
        <Routes>
          <Route
            path="/"
            element={<Home textEnter={textEnter} textLeave={textLeave} />}
          />
        </Routes>
        <Footer textEnter={textEnter} textLeave={textLeave} />
      </div>
    </>
  );
}

export default App;