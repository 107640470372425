import firebase from "firebase/compat/app"; // Use the compat version
import "firebase/compat/firestore"; // Use the compat version for Firestore

// Firebase configuration (Add your own credentials here)
const firebaseConfig = {
  apiKey: "AIzaSyA6TdQh4sW87CEY8PLIbXAWU0Sfi7A8t8Y",
  authDomain: "russel-portfolio.firebaseapp.com",
  projectId: "russel-portfolio",
  storageBucket: "russel-portfolio.appspot.com",
  messagingSenderId: "593905407509",
  appId: "1:593905407509:web:fe8b19732cb0afe6e7201c",
  measurementId: "G-MKFBLQRXTM",
};

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const firestore = firebase.firestore(); // Use firestore from compat API

export { firestore };
